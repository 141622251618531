import { useMemo , useRef, useState, useEffect } from "react"
import { useInView } from 'react-intersection-observer';
import { useCookies } from "react-cookie"
import { useIsMobile } from "../useIsMobile"
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { getWebsiteID } from "/lib/common-functions"
import { getReduxSession } from "/lib/sessionHandler"
import useWindowDimensions from "/lib/hooks/useWindowDimensions";
import { useBannerGAEvent } from "/components/Home/useBannerGAEvent";
import { getWebsiteURL } from "/lib/services/helpers/getWebsiteURL";
import { useRouter } from "next/router";
import { event } from "/lib/ga";

export const useHomepage = ({ isSsrMobile, cms_data, homePageSeoData }) => {
    const isMobileType = useIsMobile(isSsrMobile);
    const [cookie, setCookie] = useCookies(["store"])
    const [storeid, setStoreid] = useState(1)
    const [prefixUrl, setPrefixUrl] = useState('')
    const [loadLivelyCond, setLoadLivelyCond] = useState(false);
    const session = getReduxSession();
    const { width } = useWindowDimensions();
    
    const eventCommonObj = useMemo(() => EventCommonObj(session, cookie),[session, cookie])
    let banner_seq = cms_data.homepage_banner_slider_banner_seq ? cms_data.homepage_banner_slider_banner_seq : "1,2,3,4,5,6"
    banner_seq = banner_seq.split(",")
    const banner = useMemo(() => {
        return [
            {
                id: 1,
                link: cms_data.homepage_banner_slider_link1,
                image: cms_data.homepage_banner_slider_slider1_m,
                image_d: cms_data.homepage_banner_slider_slider1,
                title: cms_data.homepage_banner_slider_tag1,
                text: cms_data.homepage_banner_slider_text1,
                btn: cms_data.homepage_banner_slider_btn1,
                seq: parseInt(cms_data.homepage_banner_slider_totalbanner) > 0 ? banner_seq[0] : 10,
                url_m: cms_data.homepage_banner_slider_banner_1_moile_url,
                url_d: cms_data.homepage_banner_slider_banner_1_url,
            },
            {
                id: 2,
                link: cms_data.homepage_banner_slider_link2,
                image: cms_data.homepage_banner_slider_slider2_m,
                image_d: cms_data.homepage_banner_slider_slider2,
                title: cms_data.homepage_banner_slider_tag2,
                text: cms_data.homepage_banner_slider_text2,
                btn: cms_data.homepage_banner_slider_btn2,
                seq: parseInt(cms_data.homepage_banner_slider_totalbanner) > 1 ? banner_seq[1] : 10,
                url_m: cms_data.homepage_banner_slider_banner_2_moile_url,
                url_d: cms_data.homepage_banner_slider_banner_2_url,
            },
            {
                id: 3,
                link: cms_data.homepage_banner_slider_link3,
                image: cms_data.homepage_banner_slider_slider3_m,
                image_d: cms_data.homepage_banner_slider_slider3,
                title: cms_data.homepage_banner_slider_tag3,
                text: cms_data.homepage_banner_slider_text3,
                btn: cms_data.homepage_banner_slider_btn3,
                seq: parseInt(cms_data.homepage_banner_slider_totalbanner) > 2 ? banner_seq[2] : 10,
                url_m: cms_data.homepage_banner_slider_banner_3_moile_url,
                url_d: cms_data.homepage_banner_slider_banner_3_url,
            },
            {
                id: 4,
                link: cms_data.homepage_banner_slider_link4,
                image: cms_data.homepage_banner_slider_slider4_m,
                image_d: cms_data.homepage_banner_slider_slider4,
                title: cms_data.homepage_banner_slider_tag4,
                text: cms_data.homepage_banner_slider_text4,
                btn: cms_data.homepage_banner_slider_btn4,
                seq: parseInt(cms_data.homepage_banner_slider_totalbanner) > 3 ? banner_seq[3] : 10,
                url_m: cms_data.homepage_banner_slider_banner_4_moile_url,
                url_d: cms_data.homepage_banner_slider_banner_4_url,
            },
            {
                id: 5,
                link: cms_data.homepage_banner_slider_link5,
                image: cms_data.homepage_banner_slider_slider5_m,
                image_d: cms_data.homepage_banner_slider_slider5,
                title: cms_data.homepage_banner_slider_tag5,
                text: cms_data.homepage_banner_slider_text5,
                btn: cms_data.homepage_banner_slider_btn5,
                seq: parseInt(cms_data.homepage_banner_slider_totalbanner) > 4 ? banner_seq[4] : 10,
                url_m: cms_data.homepage_banner_slider_banner_5_moile_url,
                url_d: cms_data.homepage_banner_slider_banner_5_url,
            },
            {
                id: 6,
                link: cms_data.homepage_banner_slider_link6,
                image: cms_data.homepage_banner_slider_slider6_m,
                image_d: cms_data.homepage_banner_slider_slider6,
                title: cms_data.homepage_banner_slider_tag6,
                text: cms_data.homepage_banner_slider_text6,
                btn: cms_data.homepage_banner_slider_btn6,
                seq: parseInt(cms_data.homepage_banner_slider_totalbanner) > 5 ? banner_seq[5] : 10,
                url_m: cms_data.homepage_banner_slider_banner_6_moile_url,
                url_d: cms_data.homepage_banner_slider_banner_6_url,
            },
        ]
    },[])

    const testimonialData = homePageSeoData?.homepage_banner_testimonials_hp || [];
    let testimonialList:any = [];

    if(testimonialData?.[0]){
        testimonialList = useMemo(() => {
            return JSON.parse(testimonialData)
        },[])
    }   
    const homeSeoData = homePageSeoData?.homepage_banner_seo_group_homepage_feild || [];
    let homeSeo:any = []

    if (homeSeoData) {
        homeSeo = useMemo(() => {
            return JSON.parse(homeSeoData)
        },[])
    }

    const { ref: productSection2, inView: productSection2View } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    const { ref: productSection3, inView: productSection3View } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    const { ref: videoBannerSec2, inView: videoBannerSec2View } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    const { ref: RecentPurchasec, inView: RecentPurchaseView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    const { ref: ProductSec1, inView: ProductSec1View } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 500px 0px"
    });

    const { ref: videoBannerSec3, inView: videoBannerSec3View } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    const { ref: awardWinningSection, inView: awardWinningSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    const { ref: ayurvedicTherapy, inView: ayurvedicTherapyView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    const { sendBannerClickGAEvent, sendHeroBannerImpressionGAEvent } = useBannerGAEvent({
        cms_data,
        banner,
        eventCommonObj
    });

    const value = useMemo(() => {
        return [{
            isMobileType,
            cms_data
        }]
    }, [cms_data, session, isMobileType])

    useEffect(() => {
        let debouncedTimer;

        const scrollHandler = () => {
            debouncedTimer = setTimeout(() => {
                if(!loadLivelyCond && window.pageYOffset > 200){
                    setLoadLivelyCond(true);
                }
            },500)
        };

        window.addEventListener('scroll', scrollHandler);

		return () => {
            clearTimeout(debouncedTimer);
			window.removeEventListener('scroll', scrollHandler);
		}
	}, []);

    useEffect(() => {
        if(loadLivelyCond){ 
            try {
                const script1 = document.createElement('script');
                script1.type = 'module';
                script1.async = true;
                // script1.src = 'https://feed.lively.li/shoppableFeedsV3.min.js';
                script1.src = 'https://video.lively.li/LIVELY_SF_FEED.js';
                document.head.appendChild(script1);

                const script2 = document.createElement('script');
                script2.id = 'livelyAnalytics';
                script2.setAttribute('data-company-id', '7a94329b88');
                script2.type = 'text/javascript';
                script2.async = true;
                script2.src = 'https://pixel-atc.lively.li/pixel.js';
                document.head.appendChild(script2);
            
                return () => {
                    try {
                        if(document.head.contains(script1)){
                            document.head.removeChild(script1);   
                        }
                        if(document.head.contains(script2)){
                            document.head.removeChild(script2);
                        }
                    } catch (error) {
                        console.log('Error while removing script lively', error);                        
                    }
                };
            } catch (error) {
                console.log('Error while appending script lively', error);
            }
        }
    },[loadLivelyCond])

    useEffect(() => {
        setStoreid(getWebsiteID(cookie))
    }, [cookie]);

    useEffect(() => {
        setPrefixUrl(getWebsiteURL(storeid))
    }, [storeid]);

    useEffect(() => {
        window.addEventListener('load', function () {
            var id = '';
            var price = '';
            var pagetype = '';
            var event_name = '';
            pagetype = "home";
            event_name = 'home';
            const google_tag_params = {
                ecomm_prodid: id,
                ecomm_pagetype: pagetype,
                ecomm_totalvalue: parseFloat(price)
            };
            event({action: event_name, params: {
                'ecomm_prodid': google_tag_params.ecomm_prodid,
                'ecomm_pagetype': google_tag_params.ecomm_pagetype,
                'ecomm_totalvalue': google_tag_params.ecomm_totalvalue,
                'id': google_tag_params.ecomm_prodid,
                'google_business_vertical': 'retail',
                'value': google_tag_params.ecomm_totalvalue
            }});
        })
    }, [cms_data]);

    return {
        isMobileType,
        value,
        homeSeo,
        width,
        storeid,
        eventCommonObj,
        prefixUrl,
        testimonialList,
        session,
        banner,
        sendBannerClickGAEvent,
        sendHeroBannerImpressionGAEvent,
        ProductSec1,
        productSection2,
        productSection3,
        videoBannerSec2,
        videoBannerSec3,
        ayurvedicTherapy,
        awardWinningSection,
        RecentPurchasec,
        ProductSec1View,
        productSection2View,
        productSection3View,
        videoBannerSec2View,
        videoBannerSec3View,
        awardWinningSectionView,
        ayurvedicTherapyView,
        RecentPurchaseView,
    }
}