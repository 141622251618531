import React from "react";
import Image from "/components/CustomImage";
import Link from "next/link";
import dynamic from "next/dynamic";

import classes from "./navbar-desktop.module.css";
import ToggleMenu from "./toggle-menu";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FormLoader from "/components/ui/FormLoader"

import { BannerClick, BannerImpression, HeaderClick, HeaderOptionClick, ProfileOptinsClick, ReadMoreClick, TopNavigationClick, TopOfferBar, ViewAllCategoryClick } from "/lib/ga";
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { responsivePromoHome } from "/lib/services/helpers/responsive";
import { useNavbarDesktop } from "/lib/hooks/Navbar/useNavbarDesktop";

import coin4 from '~/public/images/amayawallet.inline.svg';
import login_icon from '~/public/images/login-icon.inline.svg';
import bag_icon from '~/public/images/bag-icon.inline.svg';
import register_icon from '~/public/images/register-icon.inline.svg';
import account_icon2 from '~/public/images/account-icon2.inline.svg';
import wishlist2 from '~/public/images/wishlist2.inline.svg';
import arrow_icon from '~/public/images/arrow-icon.inline.svg';
import track_icon2 from '~/public/images/track-icon2.inline.svg';
import search_icon from '~/public/images/search-icon.inline.svg';
import user_icon_desk from '~/public/images/newUserIcon.inline.svg';
import cart_icon from '~/public/images/newCartIcon.inline.svg';
import new_logo from '~/public/images/logonew.inline.svg';

const MiniCart = dynamic(() => import("../ui/MiniCart"));

const getCategoryNameByUrl = (urlKey) => {
  if (urlKey) {
    const link = urlKey?.split('/') || [];
    return link?.[link?.length - 1]
  }
}

function Navbar() {
  const {
    showLoader,
    homeTicker,
    eventCommonObj,
    stickClass,
    navigationSSR,
    StaticMenu,
    session,
    websiteurl,
    storecookie,
    customerAmmayaGroup,
    ammayaPoints,
    accountLink,
    storeType,
    salesOrderLink,
    count,
    router,
    promoArr,
    cookie,
    SubcatStaticMenu,
    catProdArr,
    categoryUrlSuffix,
    staticProductData,
    showMiniBagRender,
    crossSellData,
    miniCartData,
    wishlistLink,
    getUserCart,
    SignoutUser,
    handleChange,
    handleMiniCart
  } = useNavbarDesktop();

  return (
    <div className={classes["header-desktop-container"]}>
      <FormLoader show={showLoader} />

      <div className={classes["promo-add"]}>
        <Carousel
          swipeable
          draggable
          showDots={false}
          responsive={responsivePromoHome}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={3000}
          autoPlay
          keyBoardControl
          partialVisible
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          additionalTransfrom={-15}
        >
          {
            (homeTicker) ? (
              homeTicker.map(function (item, index) {
                // Desktop top_most_offer_bar ga_event
                const offerInfo = {
                  event: 'offer_ribbon_bar',
                  offer_name: item.text || '',
                };

                return (
                  <p onClick={() => TopOfferBar(offerInfo, eventCommonObj)} key={index}><a href={item.link}>{item.text}</a></p>

                )
              })
            ) : null
          }
        </Carousel>

      </div>

      <header className={`${classes["header-desktop"]} ${stickClass ? classes[stickClass] : ''}`}>
        <div className={`container ${classes['container']}`}>
          <div className={classes["top-header"]}>
            <div className="row">
              <div className={`col-md-4 ${classes["desk-left-header"]}`}>
                <ul >
                  {" "}
                  <li className={classes["mobile-menu"]}>
                    <ToggleMenu menudata={navigationSSR} staticmenu={StaticMenu} session={session} />
                  </li>
                  <li onClick={() => TopNavigationClick('search_icon', eventCommonObj)} className={classes["search-box"]}>
                    {" "}
                    <Link
                      prefetch={false}
                      href={`${websiteurl}/catalogsearch/result`} >

                      <img
                        loading="lazy"
                        src={search_icon}
                        alt="search icon"
                        width={40}
                        height={40}
                      />

                    </Link>
                  </li>
                </ul>
              </div>
              <div onClick={() => TopNavigationClick('logo_name', eventCommonObj)}
                className={`col-md-4 ${classes["logo-main"]}`}>
                <Link href={websiteurl + "/"}>

                  {" "}
                  <img
                    loading="lazy"
                    src={new_logo}
                    alt="logo icon"
                    width={177}
                    height={76}
                  />

                </Link>
              </div>
              <div className={`col-md-4 ${classes["right-menu-desk"]}`}>
                <ul className={classes['amayaWallet']}>
                  {/* temporary disable usd store */}
                  {/* <li onClick={() => TopNavigationClick('currency_change', eventCommonObj)}
                    className={classes["inr-desk"]}>
                    <select
                      id="lang"
                      onChange={handleChange}
                      value={storecookie.store}
                    >
                      <option value="default">₹ INR</option>
                      <option value="international">$ USD</option>
                    </select>
                  </li> */}
                  {
                    (session?.user && customerAmmayaGroup && customerAmmayaGroup == 'Amaaya')
                      ? <li className={classes["user-desk"] + " " + classes["user-amaaya"]}>
                        <Link
                          prefetch={false}
                          href={`${websiteurl}/reward/customer/info`}>

                          <span>   <img
                            loading="lazy"
                            src={coin4}
                            alt="user icon"
                            width={40}
                            height={40}
                          /> </span> <strong>{(session?.user && ammayaPoints) ? ammayaPoints : 0}</strong>

                        </Link>
                        {
                          (session?.user?.amaaya_point) ? <ul className={classes["dropdown-ul"] + " " + classes["dropdown-amaaya"]}><p>"You have <b>{ammayaPoints}</b> Amaaya points available for redemption"</p></ul> : ''
                        }
                      </li> : ''
                  }
                  <li className={classes["user-desk"]}>
                    {" "}

                    <Link
                      prefetch={false}
                      href={accountLink}
                      onClick={() => TopNavigationClick(`profile_icon`, eventCommonObj)}>

                      <span className={classes["user-desk-hi"]}>
                        {session?.user ? 'Hi ' + session.user.firstname :
                          <span>
                            <img
                              loading="lazy"
                              src={user_icon_desk}
                              alt="user icon"
                              width={40}
                              height={40}
                            />
                          </span>
                        }
                      </span>

                    </Link>
                    <ul className={classes["dropdown-ul"]}>
                      {
                        (session?.accessToken) ? null : (
                          <>
                            <li onClick={() => ProfileOptinsClick('sign_in', eventCommonObj)}><Link prefetch={false} href={accountLink}><span><img loading="lazy" src={login_icon} alt="login icon" width={19} height={19} /></span> Sign in</Link></li>
                            <li onClick={() => ProfileOptinsClick('register', eventCommonObj)}><Link prefetch={false} href={`${websiteurl}/customer/account/create`}><span><img loading="lazy" src={register_icon} alt="register icon" width={19} height={19} /></span> Register</Link></li>
                          </>
                        )
                      }

                      <li onClick={() => ProfileOptinsClick('my_cart', eventCommonObj)}><Link prefetch={false} href={`${websiteurl}/checkout/cart`}><span><img loading="lazy" src={bag_icon} alt="bag icon" width={19} height={19} /></span> My Cart</Link></li>
                      <li onClick={() => ProfileOptinsClick('my_account', eventCommonObj)}><Link prefetch={false} href={accountLink}><span><img loading="lazy" src={account_icon2} alt="account icon" width={19} height={19} /></span> My Account</Link></li>
                      <li onClick={() => ProfileOptinsClick('my_wishlist', eventCommonObj)}><Link prefetch={false} href={wishlistLink}><span><img loading="lazy" src={wishlist2} alt="wishlist icon" height={19} width={19} /></span> My WishList</Link></li>
                      {storeType != 'international' && <li onClick={() => ProfileOptinsClick('track_your_order', eventCommonObj)}><Link prefetch={false} href={salesOrderLink}><span><img loading="lazy" src={track_icon2} alt="track icon" width={19} height={19} /></span> Track your order</Link></li>}
                      {session && session.accessToken && <li><a onClick={(e) => {
                        ProfileOptinsClick('sign_out', eventCommonObj)
                        SignoutUser(storecookie, e);
                      }}><span><img loading="lazy" src={login_icon} alt="login icon" width={19} height={19} /></span> Sign out</a></li>}
                    </ul>
                  </li>
                  <li onClick={() => TopNavigationClick('view_minibag', eventCommonObj)}
                    className={classes["bag-desk"]}>
                    {" "}

                    <a onClick={(e) => { handleMiniCart(); }}>
                      <img
                        loading="lazy"
                        src={cart_icon}
                        alt="bag icon"
                        width={40}
                        height={40}
                      />
                      <span>
                        {count ? count : 0}

                      </span>
                    </a>
                  </li>
                </ul >
              </div >
            </div >
          </div >
        </div >
        <div className={classes["menu-header"]}>
          <div className="container">
            <ul>
              {/* <li><Link href={websiteurl + "/kumkumadi-week-celebration.html"}><a><span className={classes["new"]}>Offer</span>Kumkumadi Week</a></Link></li> */}
              {navigationSSR?.map(function (topcategoryparent, index) {
                if (topcategoryparent.url_path == "free-trial") {
                  return (
                    <li key={topcategoryparent.id}>
                      <Link
                        prefetch={false}
                        href={`${websiteurl}/free-trial`}
                        onClick={() => HeaderClick(topcategoryparent.name, eventCommonObj)}
                        className={
                          router.asPath ==
                            `/${topcategoryparent.url_path}`
                            ? "active"
                            : ""
                        }>
                        {(topcategoryparent && topcategoryparent.category_tag) ? <span className={classes["new"]}>{topcategoryparent.category_tag}</span> : ''}
                        {topcategoryparent.name}

                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li key={topcategoryparent.id}
                      onMouseOver={(e) => {
                        const promoData = staticProductData[topcategoryparent.url_key]?.find?.(o => o.label.toLowerCase() === "shop now")
                        if (promoData && !promoArr.current[index]) {
                          BannerImpression({
                            bannerData: [{
                              promotion_id: topcategoryparent.id || 'N/A',
                              promotion_name: promoData.text || 'N/A',
                              creative_name: topcategoryparent.name || 'N/A',
                              creative_slot: `homepage_navbar_${topcategoryparent.url_key}`,
                            }],
                            eventCommonObj: EventCommonObj(session, cookie)
                          });
                          promoArr.current[index] = true;
                        }

                        const catData = SubcatStaticMenu?.find?.(o => o.url_key === topcategoryparent.url_key);
                        if (catData && !catProdArr.current[index]) {
                          BannerImpression({
                            bannerData: catData.children.map((product, i) => {
                              return {
                                promotion_id: topcategoryparent.id || 'N/A',
                                promotion_name: product.name || 'N/A',
                                creative_name: topcategoryparent.name || 'N/A',
                                creative_slot: `homepage_navbar_${topcategoryparent.url_key}`,
                              }
                            }),
                            eventCommonObj: EventCommonObj(session, cookie)
                          })

                          catProdArr.current[index] = true;
                        }
                      }}>
                      <Link
                        prefetch={false}
                        href={{
                          pathname: "/_url-resolver",
                          query: {
                            pathname: `/${topcategoryparent.url_path + ((topcategoryparent.url_path == "free-trial") ? "" : categoryUrlSuffix)
                              }`,
                            type: "CATEGORY",
                            url_path: topcategoryparent.url_path,
                            url_key: topcategoryparent.url_key,
                            cat_id: topcategoryparent.id,
                          },
                        }}
                        as={`${websiteurl + "/" +
                          topcategoryparent.url_path + categoryUrlSuffix
                          }`}
                        onClick={() => HeaderClick(topcategoryparent.name, eventCommonObj)}
                        className={
                          router.asPath ==
                            `/${topcategoryparent.url_path + categoryUrlSuffix}`
                            ? "active "
                            : ""
                        }>
                        {(topcategoryparent && topcategoryparent.category_tag) ? <span className={classes["new"]}>{topcategoryparent.category_tag}</span> : ''}
                        {topcategoryparent.name}

                      </Link>
                      {topcategoryparent?.children.length > 0 ? (
                        <div className={`mega-menu ` + classes["mega-menu"]}>
                          <div className="container">
                            <div className="row">
                              <div className="col-6">
                                {topcategoryparent?.children[0].children.length == 0 &&
                                  <ul>
                                    {topcategoryparent?.children[0].children.length == 0 ? topcategoryparent?.children?.map((topcategorychild, index) =>
                                      <li key={index}><Link
                                        prefetch={false}
                                        href={{
                                          pathname: "/_url-resolver",
                                          query: {
                                            pathname: `/${topcategorychild
                                              ?.url_path + categoryUrlSuffix
                                              }`,
                                            type: "CATEGORY",
                                            url_path:
                                              topcategorychild?.url_path,
                                            url_key:
                                              topcategorychild?.url_key,
                                            cat_id:
                                              topcategorychild?.id,
                                          },
                                        }}
                                        as={`${websiteurl + "/" +
                                          topcategorychild?.url_path +
                                          categoryUrlSuffix
                                          }`}
                                      >
                                        {topcategorychild?.name}
                                      </Link></li>
                                    ) : ''}
                                  </ul>}
                                <div className="row">
                                  <ul className="col-6">
                                    {topcategoryparent?.children.sort((a, b) => a.position - b.position).map(
                                      (topcategorychild, index) => (
                                        index == 0 ?
                                          topcategoryparent?.children[0]?.children?.map(
                                            (topcategorylast, childindex) => (
                                              topcategorylast.url_key == 'corporate-gifting' ?
                                                <div>
                                                  <li data={topcategorylast.id} key={topcategorylast.id}>
                                                  <Link
                                                    prefetch={false}
                                                    href={`${websiteurl}/corporate-gifting`}
                                                    onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[0]?.name, topcategorylast.name, eventCommonObj)}
                                                    className={router.asPath ==
                                                      `/${topcategorylast.url_path +
                                                      categoryUrlSuffix}`
                                                      ? "active"
                                                      : ""}>

                                                    {topcategorylast.name}

                                                  </Link>
                                                </li>
                                                </div>
                                                : topcategorylast.url_key == 'e-gift-card' ?
                                                  <li key={topcategorylast.id}>
                                                    <Link
                                                      prefetch={false}
                                                      href={`${websiteurl}/gifting/e-gift-card`}
                                                      onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[0]?.name, topcategorylast.name, eventCommonObj)}
                                                      className={
                                                        router.asPath ==
                                                          `/${topcategorylast.url_path +
                                                          categoryUrlSuffix
                                                          }`
                                                          ? "active"
                                                          : ""
                                                      }>

                                                      {topcategorylast.name}

                                                    </Link>
                                                  </li>
                                                  :
                                                  <div key={childindex}>{childindex == 0 ?
                                                    <Link
                                                      prefetch={false}
                                                      href={{
                                                        pathname: "/_url-resolver",
                                                        query: {
                                                          pathname: `/${topcategoryparent?.children[0]
                                                            ?.url_path + categoryUrlSuffix
                                                            }`,
                                                          type: "CATEGORY",
                                                          url_path:
                                                            topcategoryparent?.children[0]?.url_path,
                                                          url_key:
                                                            topcategoryparent?.children[0]?.url_key,
                                                          cat_id:
                                                            topcategoryparent?.children[0]?.id,
                                                        },
                                                      }}
                                                      as={`${websiteurl + "/" +
                                                        topcategoryparent?.children[0]?.url_path +
                                                        categoryUrlSuffix
                                                        }`}
                                                      legacyBehavior>
                                                      <nav onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[0]?.name, '', eventCommonObj)}>{topcategoryparent?.children[0]?.name}</nav>
                                                    </Link>

                                                    : ''}
                                                    <li key={topcategorylast.id}>
                                                      <Link
                                                        prefetch={false}
                                                        href={{
                                                          pathname: "/_url-resolver",
                                                          query: {
                                                            pathname: `/${topcategorylast.url_path +
                                                              categoryUrlSuffix}`,
                                                            type: "CATEGORY",
                                                            url_path: topcategorylast.url_path,
                                                            url_key: topcategorylast.url_key,
                                                            cat_id: topcategorylast.id,
                                                          },
                                                        }}
                                                        as={`${websiteurl + "/" +
                                                          (topcategorylast.url_path.includes('haircare/') ?
                                                            topcategorylast.url_path.replace(/haircare/g, "hair")
                                                            : (topcategorylast.url_path.includes('skincare/') ?
                                                              topcategorylast.url_path.replace(/skincare/g, "skin") :
                                                              topcategorylast.url_path)) +
                                                          categoryUrlSuffix}`}
                                                        onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[0]?.name, topcategorylast.name, eventCommonObj)}
                                                        className={router.asPath ==
                                                          `/${topcategorylast.url_path +
                                                          categoryUrlSuffix}`
                                                          ? "active"
                                                          : ""}>

                                                        {topcategorylast.name}

                                                      </Link>
                                                    </li></div>
                                            )
                                          ) : ""
                                      ))}

                                  </ul>
                                  <ul className="col-6">
                                    {topcategoryparent?.children.sort((a, b) => a.position - b.position).map(
                                      (topcategorychild, index) => (
                                        index == 1 ?
                                          topcategoryparent?.children[1]?.children?.map(
                                            (topcategorylast, childindex) => (
                                              topcategorylast.url_key == 'corporate-gifting' ?
                                                <li data={topcategorylast.id} key={topcategorylast.id}>
                                                  <Link
                                                    prefetch={false}
                                                    href={`${websiteurl}/corporate-gifting`}
                                                    onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[1]?.name, topcategorylast.name, eventCommonObj)}
                                                    className={router.asPath ==
                                                      `/${topcategorylast.url_path +
                                                      categoryUrlSuffix}`
                                                      ? "active"
                                                      : ""}>

                                                    {topcategorylast.name}

                                                  </Link>
                                                </li>
                                                : topcategorylast.url_key == 'e-gift-card' ?
                                                  <li key={topcategorylast.id}>
                                                    <Link
                                                      prefetch={false}
                                                      href={`${websiteurl}/gifting/e-gift-card`}
                                                      onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[1]?.name, topcategorylast.name, eventCommonObj)}
                                                      className={
                                                        router.asPath ==
                                                          `/${topcategorylast.url_path +
                                                          categoryUrlSuffix
                                                          }`
                                                          ? "active"
                                                          : ""
                                                      }>

                                                      {topcategorylast.name}

                                                    </Link>
                                                  </li>
                                                  :
                                                  <div key={childindex}>{childindex == 0 ?
                                                    <Link
                                                      prefetch={false}
                                                      href={{
                                                        pathname: "/_url-resolver",
                                                        query: {
                                                          pathname: `/${topcategoryparent?.children[1]
                                                            ?.url_path + categoryUrlSuffix
                                                            }`,
                                                          type: "CATEGORY",
                                                          url_path:
                                                            topcategoryparent?.children[1]?.url_path,
                                                          url_key:
                                                            topcategoryparent?.children[1]?.url_key,
                                                          cat_id:
                                                            topcategoryparent?.children[1]?.id,
                                                        },
                                                      }}
                                                      as={`${websiteurl + "/" +
                                                        topcategoryparent?.children[1]?.url_path +
                                                        categoryUrlSuffix
                                                        }`}
                                                      legacyBehavior>
                                                      <nav onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[1]?.name, '', eventCommonObj)}>{topcategoryparent?.children[1]?.name}</nav>
                                                    </Link>

                                                    : ''}
                                                    <li key={topcategorylast.id}>
                                                      <Link
                                                        prefetch={false}
                                                        href={{
                                                          pathname: "/_url-resolver",
                                                          query: {
                                                            pathname: `/${topcategorylast.url_path +
                                                              categoryUrlSuffix}`,
                                                            type: "CATEGORY",
                                                            url_path: topcategorylast.url_path,
                                                            url_key: topcategorylast.url_key,
                                                            cat_id: topcategorylast.id,
                                                          },
                                                        }}
                                                        as={`${websiteurl + "/" +
                                                          (topcategorylast.url_path.includes('haircare/') ?
                                                            topcategorylast.url_path.replace(/haircare/g, "hair")
                                                            : (topcategorylast.url_path.includes('skincare/') ?
                                                              topcategorylast.url_path.replace(/skincare/g, "skin") :
                                                              topcategorylast.url_path)) +
                                                          categoryUrlSuffix}`}
                                                        onClick={() => HeaderOptionClick(topcategoryparent?.name, topcategoryparent?.children[1]?.name, topcategorylast.name, eventCommonObj)}
                                                        className={router.asPath ==
                                                          `/${topcategorylast.url_path +
                                                          categoryUrlSuffix}`
                                                          ? "active"
                                                          : ""}>

                                                        {topcategorylast.name}

                                                      </Link>
                                                    </li></div>
                                            )
                                          ) : ""
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              {
                                (topcategoryparent && topcategoryparent.url_key && staticProductData && (topcategoryparent.url_key in staticProductData)) ? (
                                  staticProductData[topcategoryparent.url_key].map((item, index) => (
                                    <div onClick={() => item.label.includes('Shop') && BannerClick({
                                      bannerData: {
                                        promotion_id: topcategoryparent?.id || index + 1 || 'N/A',
                                        promotion_name: item.text || 'N/A',
                                        creative_name: topcategoryparent?.name || topcategoryparent?.url_key || 'N/A',
                                        creative_slot: `homepage_navbar_${topcategoryparent.url_key}` || 'N/A',
                                      },
                                      eventCommonObj
                                    })} className="col-3" key={index}>
                                      {(item.image) ? <span>
                                        <Link
                                          prefetch={false}
                                          href={`${websiteurl}${item.link}`}><Image loading="lazy" src={item.image} alt={item.text} title={item.text} className="d-block" width={242} height={161} /> </Link>
                                      </span> : null}
                                      {(item.text) ? <p>{item.text}</p> : null}
                                      {(item.link) ? <abbr>
                                        <Link
                                          prefetch={false}
                                          href={`${websiteurl}${item.link}`}
                                          onClick={() => item.label.includes('Blog') && ReadMoreClick(item.text, getCategoryNameByUrl(item.link), eventCommonObj)}>{item.label}</Link>
                                      </abbr> : null}
                                    </div>
                                  ))
                                ) : null
                              }
                            </div>
                            <div className={classes["mega_viewbtn"]}>
                              <Link
                                prefetch={false}
                                href={{
                                  pathname: "/_url-resolver",
                                  query: {
                                    pathname: `/${topcategoryparent.url_path + ((topcategoryparent.url_path == "free-trial") ? "" : categoryUrlSuffix)}`,
                                    type: "CATEGORY",
                                    url_path: topcategoryparent.url_path,
                                    url_key: topcategoryparent.url_key,
                                    cat_id: topcategoryparent.id,
                                  },
                                }}
                                as={`${websiteurl + "/" +
                                  topcategoryparent.url_path + categoryUrlSuffix}`}
                                onClick={() => ViewAllCategoryClick(`${topcategoryparent.name || ''}`, eventCommonObj)}
                                className={router.asPath ==
                                  `/${topcategoryparent.url_path + categoryUrlSuffix}`
                                  ? "active"
                                  : ""}>
                                View All {topcategoryparent.name} <b><img loading="lazy" src={arrow_icon} width="5" height="9" alt="arrow icon" /></b>

                              </Link></div>
                          </div>
                        </div>
                      ) : (
                        SubcatStaticMenu?.filter(e => e.url_key === topcategoryparent.url_key).length > 0 &&
                        <div className={`mega-menu ` + classes['mega-menu'] + ' ' + classes["subcat-static"]}>
                          <div className="container">
                            <ul className="row">
                              {SubcatStaticMenu.map((main) => (
                                main.url_key == topcategoryparent.url_key && main.children.map((child, index) => (
                                  <li key={index} className="col-3"> <Link
                                    prefetch={false}
                                    href={child.url_path}
                                    onClick={() => BannerClick({
                                      bannerData: {
                                        promotion_id: topcategoryparent?.id || index + 1 || 'N/A',
                                        promotion_name: child?.name || 'N/A',
                                        creative_name: topcategoryparent?.name || topcategoryparent?.url_key || 'N/A',
                                        creative_slot: `homepage_navbar_${topcategoryparent.url_key}` || 'N/A',
                                      },
                                      eventCommonObj
                                    })}>

                                    <span><Image loading="lazy" src={child.img} alt={child.name} title={child.name} width={239} height={161} /></span>
                                    <strong> {child.name} </strong>

                                  </Link></li>
                                ))
                              ))}
                            </ul>
                            <div className={classes["mega_viewbtn"]}>
                              <Link
                                prefetch={false}
                                href={{
                                  pathname: "/_url-resolver",
                                  query: {
                                    pathname: `/${topcategoryparent.url_path + ((topcategoryparent.url_path == "free-trial") ? "" : categoryUrlSuffix)}`,
                                    type: "CATEGORY",
                                    url_path: topcategoryparent.url_path,
                                    url_key: topcategoryparent.url_key,
                                    cat_id: topcategoryparent.id,
                                  },
                                }}
                                as={`${websiteurl + "/" +
                                  topcategoryparent.url_path + categoryUrlSuffix}`}
                                onClick={() => ViewAllCategoryClick(`${topcategoryparent.name || ''}`, eventCommonObj)}
                                className={router.asPath ==
                                  `/${topcategoryparent.url_path + categoryUrlSuffix}`
                                  ? "active"
                                  : ""}>
                                View All {topcategoryparent.name} <b><img loading="lazy" src={arrow_icon} width="5" height="9" alt="arrow icon" /></b>

                              </Link></div>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                }
              })}

              <li onClick={() => HeaderClick('All Products', eventCommonObj)}
              ><Link href={websiteurl + "/shop.html"}>All Products</Link>
                {SubcatStaticMenu?.filter(e => e.url_key === 'allproduct').length > 0 &&
                  <div className={`mega-menu ` + classes["mega-menu"] + ' ' + classes["subcat-static"]}>
                    <div className="container">
                      <ul className="row">
                        {SubcatStaticMenu.map((main) => (
                          main.url_key == "allproduct" && main.children.map((child, index) => (
                            <li key={index} className="col-3">
                              <Link prefetch={false} href={child.url_path}>

                                <span><Image loading="lazy" src={child.img} alt={child.name} title={child.name} width={239} height={161} /></span>
                                <strong>  {child.name}  </strong>
                              </Link></li>
                          ))
                        ))}
                      </ul>
                      <div className={classes["mega_viewbtn"]}>
                        <Link prefetch={false} href={`${websiteurl}/shop.html`}>View All Products<b><img loading="lazy" src={arrow_icon} width="5" height="9" alt="arrow icon" /></b>
                        </Link> </div>
                    </div>
                  </div>
                }
              </li>
            </ul>
          </div>
        </div>
      </header >
      {showMiniBagRender ? <MiniCart getUserCart={getUserCart} crossSellData={crossSellData} websiteurl={websiteurl} miniData={miniCartData} /> : null
      }
    </div >
  );
}

export default Navbar;
